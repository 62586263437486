import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { verifyToken } from '../../services/adminAPI';

import Loader from '../Loader/Loader';

const AdminPrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isValid = await verifyToken();
        setIsAuthenticated(isValid);
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <Loader />;
  }

  return isAuthenticated ? children : <Navigate to="/admin-login" />;
};

export default AdminPrivateRoute;
