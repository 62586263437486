import React, { createContext, useState, useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Створюємо контекст
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Отримуємо товари з localStorage або починаємо з порожнього масиву
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Зберігаємо товари в localStorage щоразу, коли стан корзини змінюється
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  // Додаємо товар до корзини з кількістю (перевіряючи localStorage)
  const addToCart = product => {
    try {
      const savedCart = localStorage.getItem('cart');
      const currentCart = savedCart ? JSON.parse(savedCart) : [];

      const itemIndex = currentCart.findIndex(item => item.id === product.id);

      if (itemIndex === -1) {
        // Якщо товару немає у корзині, додаємо його з кількістю 1
        const updatedCart = [...currentCart, { ...product, quantity: 1 }];
        setCartItems(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        toast.success('Успішно додано до кошика!');
      } else {
        // Якщо товар вже є, сповіщаємо, що він вже доданий
        toast.warn('Товар вже в кошику.');
      }
    } catch (error) {
      toast.error('Помилка при додаванні товару в кошик. Спробуйте пізніше.');
    }
  };

  // Видаляємо товар з корзини
  const removeFromCart = id => {
    try {
      const updatedCart = cartItems.filter(item => item.id !== id);
      setCartItems(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      toast.error('Видалено з кошика!');
    } catch (error) {
      toast.error('Помилка при видаленні товару. Спробуйте пізніше.');
    }
  };

  // Очищуємо корзину
  const clearCart = () => {
    try {
      setCartItems([]);
      localStorage.removeItem('cart');
      toast.error('Кошик очищено!');
    } catch (error) {
      toast.error('Помилка при очищенні кошика. Спробуйте пізніше.');
    }
  };

  // Оновлюємо кількість товару
  const updateItemQuantity = (id, quantity) => {
    try {
      const updatedCart = cartItems.map(item =>
        item.id === id ? { ...item, quantity: Math.max(quantity, 1) } : item,
      );
      setCartItems(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    } catch (error) {
      toast.error('Помилка при оновленні кількості товару. Спробуйте пізніше.');
    }
  };

  // Підрахунок загальної суми всіх товарів у корзині
  const getTotalPrice = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0,
    );
  };

  // Підрахунок кількості всіх товарів у кошику
  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getTotalPrice,
        updateItemQuantity,
        getTotalItems,
      }}
    >
      {children}
      {/* Контейнер для Toastify */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </CartContext.Provider>
  );
};

// Кастомний хук для використання корзини
export const useCart = () => useContext(CartContext);
