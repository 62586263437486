import axios from 'axios';

const BASE_URL = 'https://dev.meblimriya.com';

const apiData = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

apiData.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

export const verifyToken = async () => {
  const response = await apiData.post('/admin-auth/verify-token');
  return response.data.valid;
};

export const login = async password => {
  const response = await apiData.post('/admin-auth/login', { password });
  return response.data;
};

export const getBrands = async () => {
  const catalog = await apiData.get('/brands');
  return catalog.data;
};

export const getCatalog = async () => {
  const catalog = await apiData.get('/catalog');
  return catalog.data;
};

export const getCategories = async id => {
  const categories = await apiData.get(`/catalog/${id}`);
  return categories.data;
};

export const addProduct = async productData => {
  const response = await apiData.post('/admin/product/add', productData);
  return response.data;
};

export const uploadImages = async files => {
  const formData = new FormData();
  files.forEach(file => formData.append('images', file));

  const response = await apiData.post(
    '/admin/product/upload-images',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  if (!Array.isArray(response.data.urls)) {
    throw new Error('Відповідь сервера не містить масив URL');
  }
  return response.data.urls;
};

export const getOrders = async () => {
  const orders = await apiData.get('/admin/orders');
  return orders.data;
};

export const getOrder = async id => {
  const order = await apiData.get(`/admin/orders/${id}`);
  return order.data;
};

export const updateOrderStatus = async (orderId, newStatus) => {
  const response = await apiData.put(`/admin/orders/${orderId}/status`, {
    status: newStatus,
  });
  return response.data;
};
