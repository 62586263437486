import { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CartProvider } from './context/CartContext';

import AdminPrivateRoute from './components/AdminPrivateRoute/AdminPrivateRoute';
import Loader from './components/Loader/Loader';
import ScrollToTop from './utils/ScrollToTop';

const Layout = lazy(() => import('./layout/Layout/Layout'));
const Home = lazy(() => import('./pages/Home/Home'));
const Shop = lazy(() => import('./pages/Shop/Shop'));
const CatalogItem = lazy(() => import('./pages/CatalogItem/CatalogItem'));
const Category = lazy(() => import('./pages/Category/Category'));
const Product = lazy(() => import('./pages/Product/Product'));
const Admin = lazy(() => import('./pages/Admin/Admin'));
const AdminLogin = lazy(() => import('./pages/AdminLogin/AdminLogin'));
const Page404 = lazy(() => import('./pages/Page404/Page404'));
const Cart = lazy(() => import('./pages/Cart/Cart'));

function App() {
  const location = useLocation();

  return (
    <Suspense fallback={<Loader />}>
      <CartProvider>
        <Layout>
          <ScrollToTop trigger={location} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:catalogId" element={<CatalogItem />} />
            <Route path="/shop/:catalogId/:categoryId" element={<Category />} />
            <Route
              path="/shop/:catalogId/:categoryId/:productId"
              element={<Product />}
            />
            <Route path="/cart" element={<Cart />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route
              path="/admin/*"
              element={
                <AdminPrivateRoute>
                  <Admin />
                </AdminPrivateRoute>
              }
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Layout>
      </CartProvider>
    </Suspense>
  );
}

export default App;
